define("discourse/plugins/discourse-group-membership-ip-block/discourse/initializers/group-custom-field", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "group-custom-field",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.30", api => {
        api.modifyClass("model:group", {
          pluginId: "discourse-group-membership-ip-block",
          custom_fields: {},
          asJSON() {
            return Object.assign(this._super(), {
              custom_fields: this.custom_fields
            });
          }
        });
      });
    }
  };
});